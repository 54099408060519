// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { hideVisually } from 'polished';

// utils
import { greaterThan } from '@utils/mediaQuery';

// components
import Button from '@components/Button';
import Layout from '@components/Layout';

// images
import vectorEcological from '@images/vectorEcological.svg';
import vectorSale from '@images/vectorSale.svg';
import vectorInstallation from '@images/vectorInstallation.svg';
import IconArrow from '@images/IconArrow';
import StampQuebec from '@images/StampQuebec';

// views
import HeroSection from '@views/IndexPageView/HeroSection';
import ProfilingSection from '@views/IndexPageView/ProfilingSection';
import MissionSection from '@views/IndexPageView/MissionSection';
import ReasonsSection from '@views/IndexPageView/ReasonsSection';
import ProjectsSection from '@views/IndexPageView/ProjectsSection';

// styles
import {
  Content,
  List,
  ListItem,
  ImageWrapper,
  Image,
  Description,
  Title,
} from '@views/IndexPageView/ReasonsSection/ReasonsSection.styles';
import SEO from '@src/components/SEO';
import { magnifyStyle } from '@src/styles/global';

const AccueilPage = ({ data }) => {
  const {
    projects: { nodes: projects },
  } = data;

  const langLinks = [{ langKey: 'en', href: '/en' }];

  return (
    <Layout localeLinks={langLinks}>
      <SEO
        langLinks={langLinks}
        description='Découvrez la grande variété de produits Permafil, le premier choix de solutions de rangement pratiques pour la maison ou vos projets résidentiels commerciaux.'
      />
      <HeroSection renderStamp={<StampQuebec />}>
        <p>
          Consommez local!<span css={hideVisually()}> - </span>
          <span>
            Achetez
            <br />
            nos produits Permafil!
          </span>
        </p>
      </HeroSection>

      <ProfilingSection />

      <MissionSection>
        <h2 css={hideVisually}>Notre mission</h2>

        <p css={magnifyStyle}>
          Chez Permafil, entreprise bien établie au Québec depuis plus de 30
          ans, nous avons fait des espaces de rangement commerciaux et
          domestiques notre spécialité.
        </p>

        <p>
          Qu’il s’agisse de tablettes grillagées ou en mélamine, de casiers ou
          de cloisons en treillis métallique, de supports à vélos, de toiles
          pour fenêtres, de mobilier, de présentoirs ou d’accessoires divers,
          nous nous faisons un point d’honneur de concevoir et de commercialiser
          ce qu’il y a de mieux.
        </p>

        <p>
          Pour tous nos clients, le nom Permafil représente un gage de qualité
          et de fiabilité. De plus, nos installateurs professionnels
          garantissent un travail minutieux pour une installation adéquate et
          efficace!
        </p>

        <p>
          Vous êtes à la recherche d’un sous-traitant en travaux de finition?
          Chez Permafil, nos équipes rencontrent les exigences de la CCQ et sont
          habilitées à effectuer cette catégorie de travaux partout au Québec.
        </p>
      </MissionSection>

      <ReasonsSection>
        <Title>
          Bref, choisir Permafil,
          <br />
          c’est ainsi…
        </Title>

        <Content>
          <List>
            <ListItem>
              <ImageWrapper>
                <Image src={vectorEcological} />
              </ImageWrapper>

              <Description>
                réduire votre empreinte carbonique en installant des produits
                locaux, sans compromis sur la qualité.
              </Description>
            </ListItem>

            <ListItem>
              <ImageWrapper>
                <Image src={vectorSale} />
              </ImageWrapper>

              <Description>
                vous assurer d’un service des plus professionnels avant, pendant
                et après la vente.
              </Description>
            </ListItem>

            <ListItem>
              <ImageWrapper>
                <Image src={vectorInstallation} />
              </ImageWrapper>

              <Description>
                vous allier à notre équipe d’installation qui fait de vos
                objectifs les siens.
              </Description>
            </ListItem>
          </List>
        </Content>

        <Button
          outlined
          primary
          renderIcon={<IconArrow />}
          to='/nous-contacter'
          tag='link'
          css={`
            ${greaterThan(480)} {
              align-self: center;

              min-width: 408px;
            }
          `}
        >
          Contactez-nous
        </Button>
      </ReasonsSection>

      <ProjectsSection projects={projects} />
    </Layout>
  );
};

AccueilPage.propTypes = {
  data: PropTypes.shape({
    projects: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape()),
    }),
  }).isRequired,
};

export default AccueilPage;

export const query = graphql`
  query accueilPageQuery {
    projects: allSanityProject(
      filter: { localization: { locale: { eq: "fr" } } }
      sort: { fields: sortOrder, order: ASC }
      limit: 3
    ) {
      nodes {
        _type
        id
        slug {
          current
        }
        title
        city
        state
        pictures {
          hotspot {
            x
            y
          }
          asset {
            fluid(maxWidth: 416, maxHeight: 318) {
              ...GatsbySanityImageFluid
            }
          }
          alt
        }
        localization {
          locale
        }
      }
    }
  }
`;
