import React from 'react';

const StampQuebec = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 91.764 92.006'
    {...props}
  >
    <g
      id='logo_quebec'
      data-name='logo quebec'
      transform='translate(-223.828 -81.984)'
    >
      <path
        d='M268.19,108.4c-9.067,1.945-8.844,13.545-8.641,16.451h-2.2c-.095-2.207-.231-10.92,3.107-15.125,3.84-4.835,3.608-15.549-5.169-21.712h0c-8.777,6.164-9.05,16.88-5.214,21.715,3.338,4.2,3.2,12.913,3.11,15.122h-2.2c.2-2.909.426-14.506-8.641-16.451-10.382-2.226-14.479,11.516-5.484,15.571,0,0,.744-4.573,4.252-5.852s6.71,1.906,6.727,6.732h-5.779v3.982h9.666c-.123,1.806-1,4.9-5.718,4.28,0,0-.075,5.322,5.489,2.8,0,0-.22,4.915,3.762,6.671l0,0c3.982-1.755,3.765-6.674,3.765-6.674,5.567,2.525,5.492-2.8,5.492-2.8-4.718.619-5.6-2.472-5.718-4.277h9.714v-3.982H262.7c.014-4.829,3.227-8.011,6.727-6.732s4.252,5.852,4.252,5.852C282.669,119.911,278.573,106.168,268.19,108.4Z'
        transform='translate(14.441 10.766)'
        fill='none'
        stroke='#00c6f5'
        strokeMiterlimit='10'
        strokeWidth='1'
      />
      <circle
        cx='35.125'
        cy='35.125'
        r='35.125'
        transform='translate(234.586 92.642)'
        fill='none'
        stroke='#00c6f5'
        strokeMiterlimit='10'
        strokeWidth='1'
      />
      <g transform='translate(223.828 81.984)'>
        <path
          d='M231.238,105.688l-.191-1.072-2.664.484-.884-4.9-.038-.2-1.029.185.922,5.1-1.461.264-.971-5.391-.038-.2-1.029.185,1.167,6.46.038.2,5.973-1.078Z'
          transform='translate(-223.779 -49.882)'
          fill='#00c6f5'
        />
        <path
          d='M230.059,104.229l-1.429-.947.056-4.509,1.455-.922.022-1.262-6.32,3.876-.014,1,6.219,4.026Zm-2.536-4.762.106-.07-.036,3.23-2.566-1.65Z'
          transform='translate(-223.828 -55.895)'
          fill='#00c6f5'
        />
        <path
          d='M230.041,93.781a1.5,1.5,0,0,0-1.716.711,1.351,1.351,0,0,0-1.059-.936,1.473,1.473,0,0,0-1.452.359,3.136,3.136,0,0,0-.738,1.533l-1.067,4.509-.045.206,5.9,1.39.2.045,1.212-5.141a2.541,2.541,0,0,0-.151-1.939A1.662,1.662,0,0,0,230.041,93.781Zm-4.812,5.559.867-3.653c.259-1.106.66-1.19,1.037-1.087.541.125.613.513.332,1.719l-.791,3.363Zm4.481-4.561a.751.751,0,0,1,.507.326,1.5,1.5,0,0,1,.05,1.112L229.3,100.3l-1.613-.379.886-3.762C228.854,94.987,229.147,94.642,229.71,94.778Z'
          transform='translate(-223.585 -61.409)'
          fill='#00c6f5'
        />
        <path
          d='M230.571,98.914l.471-.981-1.881-.911.953-1.964L233.3,93.3l.777-1.611-3.246,1.836a2.6,2.6,0,0,0,.228-1.925,1.937,1.937,0,0,0-1.059-1.1,1.975,1.975,0,0,0-1.549-.156,2.645,2.645,0,0,0-1.41,1.466l-2.123,4.372,5.47,2.647Zm-4.238-3.263,1.652-3.408c.594-1.223,1.3-.875,1.53-.761s.945.463.343,1.7l-1.638,3.386Z'
          transform='translate(-221.886 -67.23)'
          fill='#00c6f5'
        />
        <path
          d='M231.719,93.456l.607-.9-5.214-3.489-.607.906,5.04,3.369Z'
          transform='translate(-219.046 -69.333)'
          fill='#00c6f5'
        />
        <path
          d='M230.567,94.832a4.98,4.98,0,0,0,3.505-2,9.407,9.407,0,0,0,1.675-2.586l.078-.181-.92-.476-.092.2A9.323,9.323,0,0,1,233.3,92.14c-1.5,1.675-2.87,2.051-3.949,1.081s-.861-2.368.635-4.04a9.238,9.238,0,0,1,2.171-1.775l.184-.111-.571-.858-.17.095a9.242,9.242,0,0,0-2.391,1.951,5.486,5.486,0,0,0-1.6,3.174,2.851,2.851,0,0,0,1.017,2.382A2.881,2.881,0,0,0,230.567,94.832Z'
          transform='translate(-217.103 -74.032)'
          fill='#00c6f5'
        />
        <path
          d='M232.017,92.143l-.081-1.719,3.681-2.614,1.5.6.1.045,1.031-.733-6.858-2.828-.814.582.41,7.4Zm-.343-5.991,2.695,1.129.125.047L231.858,89.2Z'
          transform='translate(-211.768 -76.794)'
          fill='#00c6f5'
        />
        <path
          d='M236.1,90.96l-1.744-4.1-.122-.269,6.795,2.267.884-.37-2.457-5.774-1,.424,1.867,4.373-6.8-2.269-.876.37,2.46,5.774Z'
          transform='translate(-208.088 -80.685)'
          fill='#00c6f5'
        />
        <path
          d='M239.064,83.8l.966,4.92.038.2,1.067-.212-1-5.121,2.991-.582-.2-1.029-6.846,1.339-.2.038.2,1.029Z'
          transform='translate(-202.306 -81.984)'
          fill='#00c6f5'
        />
        <path
          d='M245.358,82.06a4.767,4.767,0,0,0-3.154.513,2.926,2.926,0,0,0-1.368,2.176c-.245,1.853.978,3.257,3.21,3.695.025,1.287,1.577,1.494,3.516,1.744l.209.028.131-.992-.209-.028a6.594,6.594,0,0,1-2.558-.621c.1,0,.2.006.29.006,2.02,0,3.383-1.017,3.608-2.747C249.3,83.874,247.852,82.395,245.358,82.06Zm-.131,1.039c1.878.245,2.892,1.218,2.711,2.591a1.957,1.957,0,0,1-.738,1.332,4.258,4.258,0,0,1-4.913-.652,2.075,2.075,0,0,1,.376-2.812,2.933,2.933,0,0,1,1.808-.513A5.561,5.561,0,0,1,245.227,83.1Z'
          transform='translate(-193.499 -81.956)'
          fill='#00c6f5'
        />
        <path
          d='M247,89.79a6.117,6.117,0,0,0,2.107.454,2.291,2.291,0,0,0,2.313-1.65l1.31-3.478-1.017-.382-1.31,3.478a1.323,1.323,0,0,1-1.048.95,4.035,4.035,0,0,1-1.99-.351c-1.725-.649-2.288-1.382-1.889-2.452l1.312-3.478-1.02-.382-1.31,3.478C243.83,87.664,244.661,88.913,247,89.79Z'
          transform='translate(-187.332 -81.061)'
          fill='#00c6f5'
        />
        <path
          d='M252.317,84.474,249,83.84l-.174.74,3.284.844Z'
          transform='translate(-179.175 -78.67)'
          fill='#00c6f5'
        />
        <path
          d='M252.782,92.9l.566-.884-4.936-3.154.9-1.4,4.367,2.8.566-.884-4.367-2.792.795-1.252L255.46,88.4l.566-.882-5.706-3.649-3.385,5.293Z'
          transform='translate(-182.549 -78.621)'
          fill='#00c6f5'
        />
        <path
          d='M253.089,93.9a2.671,2.671,0,0,0,1.6.875c.039,0,.084,0,.128,0a1.66,1.66,0,0,0,1.2-.546,1.467,1.467,0,0,0,.287-1.825,1.329,1.329,0,0,0,1.343-.421,1.5,1.5,0,0,0,.493-1.09,2.607,2.607,0,0,0-.961-1.755l-3.433-3.394-4.4,4.45Zm3.355-4.015c.8.8.658,1.165.379,1.446a.577.577,0,0,1-.415.209h0c-.323,0-.719-.326-1.215-.819L252.743,88.3l1.037-1.045Zm-1.106,3.55c-.265.27-.711.518-1.516-.279l-2.973-2.943,1.159-1.17,2.739,2.706C255.6,92.593,255.747,93.017,255.337,93.435Z'
          transform='translate(-178.25 -75.263)'
          fill='#00c6f5'
        />
        <path
          d='M257.2,90.552l-2.757-1.946-.465.6,2.648,2.125Z'
          transform='translate(-169.974 -70.154)'
          fill='#00c6f5'
        />
        <path
          d='M251.529,91.792l3.671,5.646.114.174.874-.571L253,92.134l1.391-.906,2.827,4.348.874-.571-2.825-4.343,1.244-.811,3.1,4.77.874-.577-3.578-5.5-.112-.174L251.7,91.68Z'
          transform='translate(-174.34 -70.575)'
          fill='#00c6f5'
        />
        <path
          d='M255.6,99.94l.842-.6-.134-.17a9.188,9.188,0,0,1-1.4-2.416c-.8-2.1-.51-3.475.839-4s2.494.312,3.3,2.408a9,9,0,0,1,.582,2.728l.017.217,1.031-.117-.008-.2a9.513,9.513,0,0,0-.649-3.009,5.068,5.068,0,0,0-2.4-3.037,3.074,3.074,0,0,0-4.054,1.845,5.5,5.5,0,0,0,.371,3.528,9.413,9.413,0,0,0,1.541,2.667Z'
          transform='translate(-170.985 -64.963)'
          fill='#00c6f5'
        />
        <path
          d='M254.834,96.147a4.79,4.79,0,0,0-.477,3.171c.329,2.285,1.616,3.675,3.338,3.675a3.188,3.188,0,0,0,.482-.033c1.992-.29,3.007-2.04,2.644-4.567a4.5,4.5,0,0,0-1.574-3.093,3.1,3.1,0,0,0-4.414.847Zm1,.449a2.08,2.08,0,0,1,2.814-.4,4.281,4.281,0,0,1,.7,4.918,1.963,1.963,0,0,1-1.329.755c-1.374.2-2.355-.808-2.628-2.7A3.439,3.439,0,0,1,255.832,96.6Z'
          transform='translate(-169.415 -59.233)'
          fill='#00c6f5'
        />
        <path
          d='M254.725,98.286h-.209l-.014,1.088,6.074.046h.21l.008-1.086Z'
          transform='translate(-169.03 -52.862)'
          fill='#00c6f5'
        />
        <path
          d='M259.4,99.961c-1.663-.242-2.056,1.722-2.374,3.31l-.022.1c-.287,1.39-.476,2.2-.97,2.2l-.072-.006a.543.543,0,0,1-.365-.229,3.672,3.672,0,0,1-.209-2.307,10.366,10.366,0,0,1,1.053-3.23l.095-.192-.934-.449-.095.178a10.579,10.579,0,0,0-1.137,3.425c-.34,2.288.189,3.712,1.457,3.9,1.566.229,1.942-1.661,2.235-3.177.273-1.382.532-2.444,1.129-2.444a.381.381,0,0,1,.081.006c.808.123.63,1.755.555,2.252a13.856,13.856,0,0,1-.811,2.984l-.081.2.981.385.075-.192a14.732,14.732,0,0,0,.85-3.113C261.25,100.827,260.288,100.094,259.4,99.961Z'
          transform='translate(-169.441 -51.299)'
          fill='#00c6f5'
        />
        <path
          d='M227.166,104.512a.941.941,0,0,0-.716.053.955.955,0,0,0-.524.529,1.368,1.368,0,0,0,.7,1.541.865.865,0,0,0,.315.059,1.1,1.1,0,0,0,.446-.1.918.918,0,0,0,.5-.515,1.16,1.16,0,0,0-.084-.9A1.194,1.194,0,0,0,227.166,104.512Z'
          transform='translate(-220.18 -41.826)'
          fill='#00c6f5'
        />
        <path
          d='M234.992,112.486a5.18,5.18,0,0,0-1.53-3.606l-1.97-2.363-4.818,4.013,1.967,2.366a5.186,5.186,0,0,0,3.269,2.157,2.3,2.3,0,0,0,.24.011,2.759,2.759,0,0,0,1.792-.7A2.728,2.728,0,0,0,234.992,112.486Zm-1.75,1.031a1.7,1.7,0,0,1-1.638.385,4.789,4.789,0,0,1-2.16-1.675l-1.273-1.53,3.216-2.675,1.273,1.527C234.114,111.293,234.309,112.63,233.242,113.517Z'
          transform='translate(-218.744 -38.157)'
          fill='#00c6f5'
        />
        <path
          d='M233.171,110.387l4.457,3.527.645-.822-5.312-4.2-3.889,4.923,5.442,4.3.645-.822-4.585-3.63,1.026-1.3,4.065,3.211.648-.822-4.063-3.214Z'
          transform='translate(-214.457 -33.908)'
          fill='#00c6f5'
        />
        <path
          d='M238.674,112.7l-4.107-2.034-2.775,5.612.975.476.755-1.527,3.138,1.546a3.542,3.542,0,0,0,1.563.421,2.067,2.067,0,0,0,.663-.106,2.4,2.4,0,0,0,1.393-2.806A2.968,2.968,0,0,0,238.674,112.7Zm-.424.953c1.407.7,1,1.527.867,1.8-.365.727-1.02.867-1.959.4l-3.171-1.569,1.092-2.2Z'
          transform='translate(-209.602 -30.74)'
          fill='#00c6f5'
        />
        <path
          d='M241.413,117.124a1.393,1.393,0,0,1-.61.925,3.2,3.2,0,0,1-2.338.014c-1.792-.454-2.433-1.117-2.157-2.226l.911-3.606-1.056-.262-.908,3.606c-.44,1.744.527,2.89,2.954,3.5a7.25,7.25,0,0,0,1.717.242,3.2,3.2,0,0,0,1.106-.173,2.323,2.323,0,0,0,1.435-1.755l.914-3.611-1.053-.262Z'
          transform='translate(-203.598 -28.418)'
          fill='#00c6f5'
        />
        <path
          d='M238.419,118.868l1.086.114.629-6.253-1.086-.1Z'
          transform='translate(-197.762 -27.244)'
          fill='#00c6f5'
        />
        <path
          d='M243.338,115.41c-1.468-.006-2.594-.067-2.617-.733a.516.516,0,0,1,.15-.4,3.327,3.327,0,0,1,1.962-.555,14.54,14.54,0,0,1,3.076.24l.206.045.2-1.039-.206-.039a15.034,15.034,0,0,0-3.213-.254,5.89,5.89,0,0,0-2.123.41,1.711,1.711,0,0,0-1.151,1.675c.067,1.68,2.079,1.7,3.695,1.719h.114c1.48.025,2.321.067,2.341.619.028.747-1.485,1-2.385,1.034a10.317,10.317,0,0,1-3.372-.432l-.2-.059-.27,1,.189.059a10.544,10.544,0,0,0,3.163.493c.137,0,.276,0,.415-.008a4.9,4.9,0,0,0,3.14-1,1.56,1.56,0,0,0,.426-1.154C246.81,115.435,244.887,115.421,243.338,115.41Z'
          transform='translate(-195.754 -27.18)'
          fill='#00c6f5'
        />
        <path
          d='M248.678,116.83l-1.7-4.944-.814.28-2.106,1.554.618.759,1.644-1.154,1.322,3.856-2.093.716.335.99,5.023-1.714.2-.068-.335-.993Z'
          transform='translate(-187.683 -28.566)'
          fill='#00c6f5'
        />
        <path
          d='M248.549,111.25c-1.792.989-2.486,2.474-1.767,3.784a2.021,2.021,0,0,0,1.246,1,3.3,3.3,0,0,0,2.46-.4,3.726,3.726,0,0,0,1.783-1.967,1.9,1.9,0,0,1,.017,1.028,2.582,2.582,0,0,1-1.363,1.468,9.541,9.541,0,0,1-2.148.819l-.209.056.29,1,.192-.042a9.2,9.2,0,0,0,2.318-.892c2.045-1.126,2.586-2.814,1.519-4.751C251.921,110.6,250.413,110.219,248.549,111.25Zm1.611,3.455c-.816.449-1.945.666-2.427-.2-.418-.769.072-1.658,1.279-2.327a2.482,2.482,0,0,1,1.833-.362,1.168,1.168,0,0,1,.719.571.954.954,0,0,1,.081.752A3.13,3.13,0,0,1,250.16,114.705Z'
          transform='translate(-183.32 -30.713)'
          fill='#00c6f5'
        />
        <path
          d='M254.1,111.256a1.524,1.524,0,0,0,.028-1.814,1.444,1.444,0,0,0-1.012-.521,1.312,1.312,0,0,0-.148-.006,4.37,4.37,0,0,0-2.466,1.165c-1.521,1.279-1.984,2.577-1.24,3.472a1.527,1.527,0,0,0,1.789.281,1.544,1.544,0,0,0-.061,1.987,1.415,1.415,0,0,0,1.117.5,2.164,2.164,0,0,0,.66-.109,5.941,5.941,0,0,0,1.928-1.156,4.354,4.354,0,0,0,1.708-2.486,1.4,1.4,0,0,0-.334-1.042A1.536,1.536,0,0,0,254.1,111.256Zm-2.923-.376a3.237,3.237,0,0,1,1.669-.853.667.667,0,0,1,.532.234c.474.566-.588,1.56-1.064,1.964s-1.644,1.273-2.112.725C249.648,112.292,250.7,111.284,251.175,110.879Zm2.848,3.374a4.635,4.635,0,0,1-1.39.869.752.752,0,0,1-.9-.145.524.524,0,0,1-.131-.4c.025-.29.267-.816,1.282-1.672a3.554,3.554,0,0,1,1.794-.984.56.56,0,0,1,.446.2C255.658,112.758,254.533,113.825,254.023,114.254Z'
          transform='translate(-179.016 -33.874)'
          fill='#00c6f5'
        />
        <path
          d='M253.509,109.484a3.755,3.755,0,0,0-.914,2.505,1.922,1.922,0,0,1-.41-.959,2.573,2.573,0,0,1,.711-1.878,9.878,9.878,0,0,1,1.68-1.566l.173-.128-.644-.822-.162.117a9.534,9.534,0,0,0-1.817,1.705c-1.466,1.811-1.326,3.578.39,4.968a3.2,3.2,0,0,0,1.992.814c.07,0,.145-.006.217-.011a3.352,3.352,0,0,0,2.232-1.413,4.183,4.183,0,0,0,1.02-2.3,2.147,2.147,0,0,0-.811-1.873C255.942,107.645,254.4,108.378,253.509,109.484Zm.652.747a2.33,2.33,0,0,1,1.616-.981,1.107,1.107,0,0,1,.7.256c.674.549.557,1.558-.309,2.625a2.413,2.413,0,0,1-1.563,1.028,1.131,1.131,0,0,1-.875-.251C252.963,112.284,253.568,110.963,254.161,110.231Z'
          transform='translate(-175.07 -37.945)'
          fill='#00c6f5'
        />
        <path
          d='M255.46,104.581a.939.939,0,0,0-.722-.05,1.151,1.151,0,0,0-.624.644,1.194,1.194,0,0,0-.106.881.913.913,0,0,0,.513.552.966.966,0,0,0,1.371-.6,1.171,1.171,0,0,0,.086-.894A.963.963,0,0,0,255.46,104.581Z'
          transform='translate(-169.99 -41.797)'
          fill='#00c6f5'
        />
      </g>
    </g>
  </svg>
);

export default StampQuebec;
